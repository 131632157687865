const menu = [
    {
        label: "Portfolio",
        path: "/portfolio"
    },
    {
        label: "Blog",
        path: "/blog"
    }
]

export default menu;