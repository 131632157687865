const themes = [
    {
        label: "Black",
        name: "black"
    },
    {
        label: "White",
        name: "white"
    },
    {
        label: "Cyberpunk",
        name: "cyberpunk"
    }
]

export default themes;